import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import Inner from "../../layout/pageInner/Inner"
import { graphql, useStaticQuery } from "gatsby"
import Breadcrumbs from "../../atoms/breadcrumb"

const Heading = ({
  fluid,
  subTitle,
  titleExtra,
  location,
  product,
  titleOverload = false,
}) => {
  const { file } = useStaticQuery(PLACEHOLDER)
  const { pathname } = location

  const title = titleOverload
    ? titleOverload
    : React.useMemo(() => {
        if (pathname) {
          const noSlash = pathname.split("/").filter((x) => x !== "")
          const lastElement = noSlash[noSlash.length - 1]
          return lastElement
            .split("-")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")
        }
      }, [pathname])

  return (
    <StyledBackground fluid={fluid || file.childImageSharp.fluid}>
      <Wrap>
        <Inner>
          <Flex>
            <Title
              dangerouslySetInnerHTML={{
                __html: title == "Faq" ? title.toUpperCase() : title,
              }}
            />
            {titleExtra && <TitleAppend>{titleExtra}</TitleAppend>}
            <Breadcrumbs pathname={pathname} color="white" product={product} />
            <SubTitle>{subTitle}</SubTitle>
          </Flex>
        </Inner>
      </Wrap>
    </StyledBackground>
  )
}

export default Heading

const Wrap = styled.div`
  min-height: 300px;
  background: linear-gradient(
    127.54deg,
    rgba(22, 34, 50, 0.76) 0%,
    rgba(34, 50, 71, 0.48) 100%
  );
`

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 300px;
  justify-content: center;
`

const Title = styled.h1`
  color: white;
  font-family: Roboto;
  font-size: 48px;
  font-weight: 300;
  line-height: 57px;
  margin-bottom: 10px;
`
const TitleAppend = styled.div`
  color: white;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 100;
  line-height: 36px;
  margin-bottom: 10px;
`
const SubTitle = styled.p`
  opacity: 0.9;
  color: #ffffff;
  font-family: Roboto;
  font-size: 18px;
  line-height: 26px;
  margin-top: 20px;
`

const Crumbs = styled.div`
  color: white;
  a {
    color: #223247;
    font-family: Roboto;
    font-size: 16px;
    line-height: 23px;
  }
`

const StyledBackground = styled(BackgroundImage)``

const PLACEHOLDER = graphql`
  {
    file(relativePath: { eq: "header-fallback.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
