import React from "react"
import Layout from "../zzz/layout"
import { useStaticQuery, graphql } from "gatsby"
import Heading from "../zzz/organisms/heading"
import styled from "styled-components"
import Inner from "../zzz/layout/pageInner/Inner"
import SEO from "../components/SEO/seo"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const ComfortLevelPage = ({ location }) => {
  const {
    page: {
      seo,
      title,
      pageId,
      heading,
      content,
      featuredImage,
      acfComfortLevels: { comfortLevels },
    },
  } = useStaticQuery(PAGE_QUERY)

  return (
    <Layout location={location}>
      <SEO title={title} location={location} post_id={pageId} seo={seo} />
      <Heading
        location={location}
        subTitle={heading.subTitle}
        fluid={featuredImage.imageFile.localFile.childImageSharp.fluid}
      />
      <Inner>
        <LevelsWrapper>
          {comfortLevels.map((level) => (
            <div key={level.title}>
              <h2>{level.title}</h2>
              <div dangerouslySetInnerHTML={{ __html: level.description }} />
            </div>
          ))}
        </LevelsWrapper>
      </Inner>
      <ContentWrapper>
        <Inner>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </Inner>
      </ContentWrapper>
    </Layout>
  )
}

// ====================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ====================
const LevelsWrapper = styled.div`
  padding: 60px 0;
  h2 {
    margin: 1rem 0 0.5rem 0;
    text-transform: capitalize;
  }
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    grid-template-columns: 1fr;
  }
`
const ContentWrapper = styled.div`
  background: ${({ theme }) => theme.colors.tusk["000"]};
  padding: 60px 0;
  h2 {
    margin: 1rem 0 0.5rem 0;
    text-transform: capitalize;
  }
  ul {
    list-style-type: disc;
    margin: 1rem;
  }
`

// ==================
// 	👨‍💻👨‍💻 QUERY 👨‍💻👨‍💻
// ==================

const PAGE_QUERY = graphql`
  {
    page: localWpGraphQlPages(
      slug: { eq: "the-ultimate-guide-to-mattress-firmness" }
    ) {
      seo {
        yoastTitle
        yoastDescription
        yoastMeta
        yoastSchema
        woocommerceSchema
      }
      title
      pageId
      content
      heading {
        subTitle
      }
      featuredImage {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      acfComfortLevels {
        comfortLevels {
          description
          title
        }
      }
    }
  }
`

export default ComfortLevelPage
